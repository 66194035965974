<template>
  <div class="page-docs split-cols">
    <SeniorMenu
      class="fixed-col"
      :linkslist="seniorLinksList" />
    <div class="error-page http404 flex-col">
      <div class="flex-col">
        <div class="desc-area">
          <h1>Oops! Page not found.</h1>
          <p>The page you are looking for was moved, removed, renamed or never existed!</p>
          <section class="page-related-api show-on-desktop">
            <p>May we interest you in other APIs</p>
            <ul>
              <li
                v-for="(link, i) in recommendedApiLinks"
                :key="i">
                <rz-link-item :linkClass="'btn rounded smaller-on-mobile'" :link="link" />
              </li>
            </ul>
          </section>
        </div>
        <div class="image-area">
          <div class="image-item">
            <img src="@/assets/artwork-error-bg.png" alt="Artwork">
          </div>
        </div>
      </div>
      <section class="page-related-api hide-on-desktop">
        <p>May we interest you in other APIs</p>
        <ul>
          <li
            v-for="(link, i) in recommendedApiLinks"
            :key="i">
            <rz-link-item :linkClass="'btn rounded smaller-on-mobile'" :link="link" />
          </li>
        </ul>
      </section>
      <section class="pull-left page-support-section">
        <SupportSection />
      </section>
    </div>
  </div>
</template>

<script>
import '@/assets/styles/views/error_page.scss';

import { allMetaMixins } from '@/mixin/meta';
import { mapState } from 'vuex';

import SupportSection from '@/components/SupportSection.vue';
import SeniorMenu from '@/components/SeniorMenu.vue';

export default {
  mixins: allMetaMixins(),
  title() {
    return 'Not Found!';
  },
  components: {
    SupportSection,
    SeniorMenu,
  },
  computed: {
    ...mapState({
      recommendedApiLinks: (state) => state.link.cricketRecommendedApis.links,
      bootLoaded: (state) => state.boot.instance !== null,
      seniorLinksList: (state) => state.link.cricketDocsSenior,
    }),
  },
  serverPrefetch() {
    return this.fetchData();
  },
  mounted() {
    if (!this.bootLoaded) {
      this.fetchData();
    }
  },
  methods: {
    fetchData() {
      return this.$store.dispatch('link/fetchCricketDocsSenior').then(() => {
      }).then(() => {
        return this.$store.dispatch('link/fetchCricketRecommendedApis');
      }).catch((err) => {
        console.log('Error on Senior Data', err.message);
      });
    },
  },
};
</script>
