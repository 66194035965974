var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.linkslist && _vm.linkslist.links)?_c('div',{staticClass:"senior-menu-list",class:_vm.levelClass},[(_vm.level !== 0)?_c('div',{staticClass:"menu-name"},[_vm._v(_vm._s(_vm.linkslist.name))]):_vm._e(),_c('ul',_vm._l((_vm.linkslist.links),function(link){return _c('li',{key:link.key,class:{
        scrollTo: ((link.isSamePath(_vm.$router, _vm.currentPath) && link.route_params.page
        && !_vm.graphQLPath))
      }},[_c('div',{staticClass:"link-line",class:{
          active: (link.isSamePath(_vm.$router, _vm.currentPath) && link.route_params.page) ||
          (_vm.graphQLPath === link.element_id) && link.url,
          'first-level': _vm.level === 0,
          'has-child': link.linkslist
        }},[_c('div',{staticClass:"active-indicator"},[_c('div',{staticClass:"box",class:{'has-child': link.linkslist}})]),_c('div',{staticClass:"link-line-item"},[(link.linkslist)?_c('SeniorMenuList',{attrs:{"linkslist":link.linkslist,"level":_vm.level + 1}}):_c('rz-link-item',{attrs:{"link":link}})],1)])])}),0)]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }