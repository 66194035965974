import { PageMetaData } from '@/core/meta';

const clientMetaDataMixin = {
  mounted() {
    const metaData = PageMetaData.newFromVM(this);
    metaData.inject(this);
  },
};

export const serverMetaDataMixin = {
  created() {
    const metaData = PageMetaData.newFromVM(this);
    metaData.inject(this);
  },
};

export const MetaDataMixin = process.env.RUN_ENV === 'client'
  ? clientMetaDataMixin
  : serverMetaDataMixin;

export function allMetaMixins() {
  return [MetaDataMixin];
}
