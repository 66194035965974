<template>
  <div class="support-section">
    <div class="support-content">
      <h2>Let's Talk!</h2>
      <div class="item">
        <img src="@/assets/icon-support-mail.svg" alt="Email Icon">
        <div class="links">
          <a href="mailto:support@sports.roanuz.com">support@sports.roanuz.com</a>
        </div>
      </div>
      <div class="item">
        <img src="@/assets/icon-support-phone.svg" alt="Phone Icon">
        <div class="links">
          <a :href="supportNumbers.usTollfree.link" class="contact">{{supportNumbers.usTollfree.label}}</a>
        </div>
      </div>
      <div class="ctc">
        <router-link class="btn focus" :to="{name: 'planReview'}">Get Started</router-link>
      </div>
    </div>
  </div>
</template>
<style lang="scss">
.support-section{
  --support-title-pad-bottom: #{size(24)};
  --support-item-pad-bottom: #{size(20 - 8)};
  --support-img-width: #{size(16)};
  --support-item-pad-left: #{size(13)};
  --support-link-pad-bottom: #{size(8)};
  --support-ctc-pad-top: #{size(48 - 20 - 8)};

  @media screen and (min-width: $breakpoint-md) {
    --support-title-pad-bottom: #{size(20)};
    --support-item-pad-bottom: #{size(35 - 8)};
    --support-img-width: #{size(20)};
    --support-item-pad-left: #{size(25)};
    --support-link-pad-bottom: #{size(8)};
    --support-ctc-pad-top: #{size(40 - 35 - 8)};
  }

  @media screen and (min-width: $breakpoint-lg) {
    --support-title-pad-bottom: #{size(56)};
    --support-item-pad-bottom: #{size(20)};
    --support-img-width: #{size(24)};
    --support-item-pad-left: #{size(21)};
    --support-link-pad-bottom: #{size(17)};
    --support-ctc-pad-top: #{size(59 - 37)};

  }

  background-color: var(--box-color-3);

  padding:
    var(--section-box-content-pad-top)
    0
    var(--section-box-content-pad-bottom)
    var(--section-box-pad-left);

  .support-content {
    padding-left: var(--docs-page-pad-left);

    h2 {
      color: var(--master-text);
      padding-bottom: var(--support-title-pad-bottom);
      font-family: var(--hero-font);
    }

    .item {
      padding-bottom: var(--support-item-pad-bottom);
      font-size: var(--title-4-font-size);
      display: flex;
      align-items: flex-start;

      img {
        width: var(--support-img-width);
        padding-top: size(5);
      }

      .links {
        padding-left: var(--support-item-pad-left);
        a {
          padding-bottom: var(--support-link-pad-bottom);
          color: var(--master-text);
          display: block;
          &.contact {
            padding-bottom: size(4);
            padding-top: size(7);
          }
        }
      }

      .secondary {
        // Used it only here, do we need to lot the whole font?
        // font-weight: 300;
      }
    }
    .ctc {
      padding-top: var(--support-ctc-pad-top);
      a {
        display: inline-block;
      }
    }
  }

  @media screen and (min-width: $breakpoint-md) {
    margin-right: size(24);
    background: url('../assets/bg-artwork.png') no-repeat;
    background-position: right;
    background-size: size(224);
  }
  @media screen and (min-width: $breakpoint-lg) {
    margin-right: size(55);
    background-size: size(319);
  }
}
</style>

<script>
import { supportNumbers } from '@/store/modules/page';

export default {
  data() {
    return {
      supportNumbers,
    };
  },
};
</script>
